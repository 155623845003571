<template>
  <section>
    <div class="content-header">
      <h2>Curriculum toolkit</h2>
    </div>
    <div class="content-wrapper">
      <p>The University of Edinburgh has developed a comprehensive <a href="https://www.ed.ac.uk/employability/staff/curriculum-design-review" target="_blank">Curriculum Toolkit</a> to help faculty, instructors and educators embed and review student development, employability and careers in their curriculum.</p>
      <p>Within the toolkit you’ll find (among many other resources), ten curriculum design elements that foster student development, employability and careers:</p>
      <ol>
        <li class="pb-3">Real-world/applied learning</li>
        <li class="pb-3">Active teaching methods</li>
        <li class="pb-3">Skills and attributes</li>
        <li class="pb-3">Employer and alumni engagement</li>
        <li class="pb-3">Career management skills and insights</li>
        <li class="pb-3">Enterprise education and mindset</li>
        <li class="pb-3">Work experience</li>
        <li class="pb-3">Reflection</li>
        <li class="pb-3">Explicit recognition and valuing of employability across the curriculum</li>
        <li>Student support system</li>
      </ol>
            <p>This is an invaluable resource for faculty, instructors and educators. How will you use it to help students in their career development?</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '03',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
